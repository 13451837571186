import { ParsedUrlQuery } from 'querystring';
import { dehydrate } from '@tanstack/query-core';
import { QueryClient } from '@tanstack/react-query';
import sumBy from 'lodash/sumBy';
import { GetServerSideProps } from 'next';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { WarningTrFilled } from '@indriver/mireska';
import { city as cityApi } from 'common/api';
import { getCommonParams, recordEvent, useClearLocalStorage, removeUndefinedKeys } from 'common/lib';
import { CategoryIcon, CustomCell, InstructionSection, InvisibleLink, Jumbotron, ReviewsSection } from 'common/ui';
import {
    getGetCatalogQueryOptions,
    useGetAllCategoriesQuery,
    useGetCatalogPromotionQuery,
    getGetCatalogPromotionQueryOptions,
} from 'entities/catalog';
import { findCityBySlug, usePageCity, useTopCities } from 'entities/city';
import { getDomainI18nConfig, selectDomainCountries } from 'entities/domain';
import { WebsiteJsonLd } from 'entities/seo';
import { useClearSelectedTasker } from 'features/mixed-model';
import { Search } from 'features/search';
import { getCanonicalData } from 'features/seo';
import { CategoryList } from 'widgets/category-list';
import { CrosslinkCities } from 'widgets/crosslink-cities';
import { CrosslinkCountries } from 'widgets/crosslink-countries';
import { DownloadAppSection } from 'widgets/download-app-section';
import { Footer } from 'widgets/footer';
import { Header } from 'widgets/header';
import { PopularServices } from 'widgets/popular-services';
import * as Styled from './index.page.styles';

interface IIndexPageQueryParams extends ParsedUrlQuery {
    citySlug?: string;
    serviceNotFound?: string;
}

interface IProps {
    noIndex?: boolean;
    canonicalLink?: string;
}

const HomePage = (pageProps: IProps) => {
    const { t, i18n } = useTranslation();

    const router = useRouter();
    const { citySlug = '', serviceNotFound } = router.query as IIndexPageQueryParams;
    const currentCity = usePageCity();

    const { data: categories } = useGetAllCategoriesQuery({ locale: i18n.resolvedLanguage, cityId: currentCity.id });
    const { data: categoriesPromotion } = useGetCatalogPromotionQuery({
        locale: i18n.resolvedLanguage,
        cityId: currentCity.id,
    });
    const totalTaskerCount = sumBy(categoriesPromotion, (promotion) => promotion.tasker_count);
    const { data: topCities = [] } = useTopCities({ countryIso: currentCity.countryIso });
    const filteredTopCities = React.useMemo(
        () => topCities.filter((cityInfo) => cityInfo.id !== currentCity.id),
        [topCities, currentCity.id],
    );

    useEffect(() => {
        recordEvent({
            actionAF: 'masters.customer.main_page_open.view',
            actionAmplitude: 'masters_customer_main_page_open_view',
            actionGA: 'masters_customer_main_page_open_view',
            payload: {
                city_id: currentCity.id,
                city_name: currentCity.name,
                country_id: currentCity.countryId,
                country_name: currentCity.countryName,
            },
        });
    }, [currentCity]);

    useClearLocalStorage();
    useClearSelectedTasker();

    useEffect(() => {
        if (serviceNotFound !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(`/${citySlug}#catalog`);
            toast.warn(t('city_switcher_service_not_found_warning_text'), {
                position: 'top-right',
                autoClose: 1000 * 5,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
                icon: () => <WarningTrFilled size={32} />,
                closeButton: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceNotFound]);

    return (
        <>
            <Head>
                <WebsiteJsonLd
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                    url={pageProps.canonicalLink!}
                    name={t('seo_meta:title_generic')}
                    alternateName='inDrive.Services'
                />
            </Head>
            <NextSeo
                noindex={pageProps.noIndex}
                title={t(`seo_meta:${currentCity.name ? 'city' : 'home'}.title`, { location: currentCity.name })}
                description={t(`seo_meta:${currentCity.name ? 'city' : 'home'}.description`, {
                    location: currentCity.name,
                })}
            />

            <Header shouldShowGeoButton />

            <Styled.BlocksWrapper>
                <Jumbotron
                    h1={
                        <Trans
                            i18nKey={t(`seo_meta:${currentCity.name ? 'city' : 'home'}.h1`, {
                                location: currentCity.name,
                            })}
                            components={{ span: <span /> }}
                        />
                    }
                />

                {categoriesPromotion && categoriesPromotion.length > 0 && (
                    <PopularServices taskerCount={Number.isNaN(totalTaskerCount) ? 0 : totalTaskerCount}>
                        {categoriesPromotion.slice(0, 5).map((promotion) => (
                            <Link
                                key={promotion.slug}
                                href={`/${citySlug}/service/${promotion.slug}?utm_source=recommended_services`}
                                rel='noopener noreferrer'
                                prefetch={false}
                                passHref>
                                <InvisibleLink>
                                    <CustomCell
                                        title={promotion.name}
                                        subtitle={
                                            promotion.tasker_count > 0 &&
                                            t('masters_service_link_title', { count: promotion.tasker_count })
                                        }
                                        icon={<CategoryIcon size={28} src={promotion.category_icon_url} opacity={1} />}
                                    />
                                </InvisibleLink>
                            </Link>
                        ))}
                    </PopularServices>
                )}

                <InstructionSection />

                <ReviewsSection />

                {categories && categories.length > 0 && (
                    <CategoryList
                        id='category-list'
                        title={t('main_page_category_list_footer_title')}
                        categories={categories}
                        search={<Search />}
                    />
                )}

                {filteredTopCities.length > 0 && <CrosslinkCities cities={filteredTopCities} />}

                <DownloadAppSection />

                <CrosslinkCountries />
            </Styled.BlocksWrapper>

            <Footer />
        </>
    );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
    const { locale = 'en' } = ctx;
    const { citySlug } = ctx.query as IIndexPageQueryParams;

    const queryClient = new QueryClient();

    const [translations] = await Promise.all([
        serverSideTranslations(locale, ['common', 'nova', 'seo_meta']),
        getCommonParams(queryClient, ctx),
    ]);

    if (typeof citySlug !== 'string') {
        return {
            props: {
                ...translations,
                dehydratedState: removeUndefinedKeys(dehydrate(queryClient)),
            },
        };
    }

    const domainI18nConfig = getDomainI18nConfig(ctx.req);
    const domainCountries = selectDomainCountries(
        domainI18nConfig,
        await queryClient.fetchQuery({
            queryKey: ['getCountries'],
            queryFn: cityApi.getCountries,
        }),
    );

    const city = findCityBySlug(citySlug, domainCountries);
    if (city === undefined) {
        return { notFound: true };
    }

    const canonicalData = getCanonicalData(domainI18nConfig, city, ctx);
    if ('redirect' in canonicalData) {
        return { redirect: canonicalData.redirect };
    }

    await Promise.all([
        queryClient.prefetchQuery(getGetCatalogQueryOptions({ locale, cityId: city.id })),
        queryClient.prefetchQuery(getGetCatalogPromotionQueryOptions({ locale, cityId: city.id })),
    ]);

    return {
        props: {
            ...translations,
            ...canonicalData,
            dehydratedState: removeUndefinedKeys(dehydrate(queryClient)),
        },
    };
};

export default HomePage;
