import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { ReactNode } from 'react';
import { useBoolean } from 'usehooks-ts';
import { ChevronDownSmall, DownArrow, PinOutline } from '@indriver/mireska';
import { Button, Heading } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import { CustomCell, InvisibleLink } from 'common/ui';
import { usePageCity } from 'entities/city';
import { CitySwitcher } from 'features/city-switcher';
import * as Styled from './popular-services.styles';

interface IProps {
    children: ReactNode;
    taskerCount: number;
}

export const PopularServices = ({ children, taskerCount }: IProps) => {
    const { t } = useTranslation();
    const currentCity = usePageCity();
    const media = useBreakpoints();
    const visibility = useBoolean();

    return (
        <section id='catalog'>
            <Styled.TextWrapper>
                <Heading type='h2' size={media.isGtS ? 'xxl' : 'xl'}>
                    {t('main_page_catalog_popular_heading')}
                </Heading>
                <CitySwitcher visibility={visibility} offset={{ mainAxis: 8, crossAxis: -15 }} width='25rem'>
                    <Styled.CitySwitcherButton type='button' onClick={visibility.toggle}>
                        {media.isGtS && <PinOutline size={32} />}
                        {currentCity.name}
                        <ChevronDownSmall size={32} />
                    </Styled.CitySwitcherButton>
                </CitySwitcher>
            </Styled.TextWrapper>
            <Styled.PromotionContainer>
                {children}
                {media.isGtS && (
                    <Link href='#category-list' rel='noopener noreferrer' prefetch={false} passHref>
                        <InvisibleLink>
                            <CustomCell
                                design='grey'
                                title={t('main_page_promotion_section_see_all')}
                                subtitle={taskerCount > 0 && t('masters_service_link_title', { count: taskerCount })}
                            />
                        </InvisibleLink>
                    </Link>
                )}
            </Styled.PromotionContainer>
            {!media.isGtS && (
                <Link href='#category-list' rel='noopener noreferrer' prefetch={false} passHref>
                    <InvisibleLink>
                        <Button design='secondary' size='l' prefix={<DownArrow size={24} />} stretched>
                            {t('main_page_promotion_section_see_all')}
                        </Button>
                    </InvisibleLink>
                </Link>
            )}
        </section>
    );
};
